'use strict';
var login = require('base/login/login');

var formValidation = require('../components/formValidation');
const cleave = require('../components/cleave');
var clientSideValidation = require('../components/clientSideValidation');
var utils = require('../components/utils');
var passwordChecker = require('../passwordChecker/passwordChecker');

/**
 * Adds the redirectURL to the form data (if applicable) and serializes the form data
 * @param {JQuery} $form - form that is being submitted that will have the redirectURL added to the form data, if it has the redirect dataset
 * @return {string} serialized form data
*/
function getFormData($form) {
    let formDataSerialized = $form.serialize();
    var customRedirectLocation = $form.data('redirect');
    if (customRedirectLocation) {
        let formDataAsObject = utils.queryStringToObject(formDataSerialized);
        formDataAsObject.redirectURL = customRedirectLocation;
        formDataSerialized = utils.objectToQueryString(formDataAsObject).substring(1); // remove the ? from the start of the query string
    }
    return formDataSerialized;
}

login.login = function () {
    $('body').on('submit', 'form.login', function (e) {
        e.preventDefault();

        var $loginForm = $(this);
        var isModal = !!$loginForm.closest('.modal, .drawer').length;
        var url = $loginForm.attr('action');
        var hostedPage = $loginForm.data('hosted-page');
        var forcePageReload = $loginForm.data('force-page-reload');

        if (hostedPage === undefined) {
            hostedPage = 'default';
        }

        var redirectPage = $loginForm.data('redirect-page');
        var isCustomRedirect = !!$loginForm.data('redirect');

        if ($loginForm.hasClass('js-form-password-checker')) {
            var $passwordField = $loginForm.find('.password-field');
            var isPasswordValid = passwordChecker.checkPasswordValidity($passwordField);

            $loginForm.removeClass('js-form-password-checker');
            $passwordField.addClass('js-password-checker');
            passwordChecker.init();

            if (!isPasswordValid) {
                return false;
            }
        }

        $.spinner().start();
        $loginForm.trigger('login:submit', e);

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: getFormData($loginForm),
            success: function (data) {
                if (!data.success) {
                    $.spinner().stop(); // only stop the spinner if we're not reloading or redirecting.
                    formValidation($loginForm, data);
                    $loginForm.trigger('login:error', data);
                } else {
                    /**
                     * The checkout shipping step needs to refresh
                     * after the Customer logs in.
                     */
                    $loginForm.trigger('login:success', data);

                    var skipDefaultNav = $loginForm.data('skip-default-nav');
                    if (skipDefaultNav) {
                        $.spinner().stop();
                        return;
                    }

                    const allowRedirect = !forcePageReload && data.redirectUrl;
                    const hasRedirectDestination = !isModal || redirectPage === 'ProPurchase' || isCustomRedirect;

                    if (allowRedirect && hasRedirectDestination) {
                        location.href = data.redirectUrl;
                    } else {
                        window.location.reload();
                    }
                }
            },
            error: function (data) {
                if (data.responseJSON.redirectUrl) {
                    window.location.href = data.responseJSON.redirectUrl;
                } else {
                    $loginForm.trigger('login:error', data);
                    $.spinner().stop();
                }
            }
        });

        return false;
    });
};

/**
 * Sets up the registration forms on the site
 * @param {JQuery} $registrationForms jQuery object for the registration forms
 */
function setupRegistrationForms($registrationForms) {
    if (!$registrationForms.hasClass('validationReady')) {
        $registrationForms.addClass('validationReady');

        // Enable or disable submit buttons.
        $registrationForms.each(function () {
            var $form = $(this);

            if (clientSideValidation.functions.quietValidation($form)) {
                $form.find('button[type=submit]').removeClass('disabled');
            } else {
                $form.find('button[type=submit]').addClass('disabled');
            }
        });

        // Enable cleave for phone number formatting
        var $phoneFields = $registrationForms.find('.profilePhoneNumber');
        $phoneFields.each(function () {
            cleave.formatPhoneField(this);
        });

        // Event listener to check if button should be enabled.
        var toValidate = $registrationForms.find('input, textarea, select').filter('[required], .js-optin-requred-field');
        toValidate.on('keyup blur', (event) => clientSideValidation.functions.setSubmitButtonState(event.target));

        // Event listener to handle rewards opt-in
        $registrationForms.on('click', '.js-btn-registration', function () {
            $(this.form).find('.js-rewards-terms').prop('checked', !$(this).hasClass('js-btn-registration-no-rewards'));
        });
    }
}

login.register = function () {
    /**
     * The registration form appears in the footer, on the account
     * login page, and on the shipping step.
     *
     * We only want to apply these event listeners one time.
     */
    var formsSelector = 'form.registration';
    setupRegistrationForms($(formsSelector));

    $('body').on('form:created', formsSelector, function () {
        setupRegistrationForms($(this));
    });

    // Handle form submission.
    $('body').on('submit', 'form.registration', function (e) {
        e.preventDefault();

        var $registrationForm = $(this);
        var $alertContainer = $('.form-validation-alert');
        /**
         * The data-hosted-page value is used below to determine
         * what should happen post-submission. This is only used
         * on the shipping page to block the redirect.
         *
         * Possible values are: default, account-login, footer, checkout-shipping
         */
        var hostedPage = $registrationForm.data('hosted-page');
        if (hostedPage === undefined) {
            hostedPage = 'default';
        }

        if (clientSideValidation.functions.quietValidation($registrationForm)) {
            // if this form is in a modal, attach the spinner to it.
            var $modalContainer = $registrationForm.closest('.modal-dialog');
            if ($modalContainer.length) {
                $modalContainer.spinner().start();
            } else {
                // otherwise attach the spinner to the page.
                $.spinner().start();
            }

            var url = $registrationForm.attr('action');
            $registrationForm.trigger('login:register', e);

            /**
             * The following is necessary so that the Account-SubmitRegistration controller
             * endpoint knows that this user should be signed up for Loyalty Rewards.
             */
            var enrollInRewards = $registrationForm.find('#rewards-terms');

            if (enrollInRewards.length > 0 && enrollInRewards.is(':checked')) {
                url = utils.appendParamToURL(url, 'loyaltyFormSubmit', true);
            }

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: getFormData($registrationForm),
                success: function (data) {
                    if (!data.success) {
                        // Client-side error added to SFRA 5.1.0
                        $registrationForm.trigger('login:register:error', data);

                        if (!data.awsError) {
                            $.spinner().stop();
                            formValidation($registrationForm, data);

                            if (data.fields) {
                                const content = Object.values(data.fields)[0];
                                if (content) {
                                    $('.form-validation-alert').empty();
                                    $alertContainer.alert({
                                        theme: 'danger',
                                        content: content,
                                        dismissible: false,
                                        autoClose: false
                                    });
                                }
                            }
                            var $invalidInput = $('form.registration .is-invalid').first();
                            var $formModal = $invalidInput.closest('#sign-up-modal');
                            var isModal = $formModal.length > 0;
                            var labelOffset = $(`label[for=${$invalidInput.attr('id')}]`).outerHeight() / 2;
                            if (isModal) {
                                $formModal.animate({
                                    scrollTop: ($formModal.scrollTop() + $invalidInput.offset().top) - labelOffset
                                }, 1000);
                            } else {
                                $('html, body').animate({
                                    scrollTop: $invalidInput.offset().top - ($('header').outerHeight() + labelOffset)
                                }, 1000);
                            }
                        } else {
                            $alertContainer.alert({
                                theme: 'danger',
                                content: $alertContainer.data('alert-content'),
                                disimissible: false,
                                autoClose: false
                            });
                            location.href = data.redirectUrl;
                        }
                    } else if (hostedPage === 'checkout-shipping') {
                        /**
                         * The checkout shipping step needs to refresh
                         * after the Customer creates an account and
                         * becomes logged in.
                         */
                        $registrationForm.trigger('login:register:success', data);
                        location.href = data.redirectCheckoutUrl;
                    } else if (hostedPage === 'footer') {
                        /**
                         * The footer signup step needs to refresh
                         * after the Customer submits footer join now form on Col/MHW US
                         */
                        $registrationForm.trigger('login:register:success', data);
                        sessionStorage.setItem('joinedRewards', 'true');
                        location.href = data.redirectUrl;
                    } else {
                        $('.form-validation-alert').empty();
                        $registrationForm.trigger('login:register:success', data);
                        if (data.redirectUrl) {
                            location.href = data.redirectUrl;
                        } else {
                            location.reload();
                        }
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                    if (err.status === 500) {
                        $.spinner().stop();
                        $alertContainer.alert({
                            theme: 'danger',
                            content: $alertContainer.data('alert-content'),
                            disimissible: false,
                            autoClose: false
                        });
                    }
                }
            });
        }
        return false;
    });
};

login.resetPassword = function () {
    $('body').on('submit', 'form.reset-password-form', function (e) {
        e.preventDefault();

        var isModal = $('#forgotPasswordModal').hasClass('show');
        var $resetPasswordForm = $(this);
        var url = $resetPasswordForm.attr('action');

        $.spinner().start();

        $resetPasswordForm.trigger('login:register', e);

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $resetPasswordForm.serialize(),
            success: function (data) {
                $.spinner().stop();

                if (!data.success) {
                    formValidation($resetPasswordForm, data);
                } else if (isModal) {
                    // display Reset Password Received modal view
                    $('#forgotPasswordModal').collapse('hide');
                    $('#resetPasswordReceivedModal').collapse('show');
                    $('.modal-title').text('Request to Reset Your Password Received');
                } else {
                    $('.request-password-title').text(data.receivedMsgHeading);
                    $('.request-password-body').empty()
                        .append('<p>' + data.receivedMsgBody + '</p>');
                    if (!data.mobile) {
                        $('#submitEmailButton').text(data.buttonText)
                            .attr('data-dismiss', 'modal');
                    } else {
                        $('.send-email-btn').empty()
                            .html('<a href="'
                                + data.returnUrl
                                + '" class="btn btn-primary btn-block">'
                                + data.buttonText + '</a>'
                            );
                    }
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
        return false;
    });
};

login.success = function () {
    /**
     * user is logged in successfully, page reloads and displays alert msg
     */
    let isLoggedIn = sessionStorage.getItem('isLoggedIn');
    let isFirstLogin = sessionStorage.getItem('isFirstLogin');
    let reloaded = sessionStorage.getItem('reloaded');

    if (isFirstLogin === 'true' && !reloaded) {
        // user has successfully registered and the page has reloaded/redirected. Show an alert message.
        $.alert(window.resources.logout.logoutMessages.join); // alert settings defined in customerSfccHelper.js createAuthMessages()
        sessionStorage.setItem('reloaded', 'true');
        sessionStorage.setItem('isTimeoutAvailable', 'true');
    } else if (isLoggedIn === 'true' && !reloaded) {
        // user has logged in successfully and the page has reloaded/redirected. Show an alert message.
        $.alert(window.resources.logout.logoutMessages.login); // alert settings defined in customerSfccHelper.js createAuthMessages()
        sessionStorage.setItem('reloaded', 'true');
        sessionStorage.setItem('isTimeoutAvailable', 'true');
    } else {
        // else listen for login or signup events
        $('body').on('login:success', 'form.login', function () {
            sessionStorage.setItem('isLoggedIn', 'true');
            sessionStorage.setItem('isTimeoutAvailable', 'false');
        });

        $('body').on('login:register:success', 'form.registration', function () {
            sessionStorage.setItem('isLoggedIn', 'true');
            sessionStorage.setItem('isFirstLogin', 'true');
            sessionStorage.setItem('isTimeoutAvailable', 'false');
        });
    }
};

login.logout = function () {
    /**
     * user logs out of their account intentionally
     */
    $('body').on('click', '.js-action__logout', function (e) {
        e.preventDefault();

        let $logoutButton = $(this);

        $.spinner().start();

        var url = new URL($logoutButton.attr('href'));

        /**
         * if the user is on a page that requires authentication to access,
         * add a query param to redirect them back to the homepage after logout
         */
        if (window.clientData.page.authRequiredToAccessPage) {
            url.searchParams.append('authRequiredToAccessPage', window.clientData.page.authRequiredToAccessPage);
        }

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (!data.success) {
                    $.alert({
                        content: data.errorMsg,
                        placement: 'banner',
                        theme: 'danger'
                    });
                } else {
                    $logoutButton.trigger('logout:success');

                    sessionStorage.setItem('isLoggedIn', 'false');
                    sessionStorage.removeItem('isFirstLogin');

                    var redirectURL = data.redirectURL ? data.redirectURL : window.location.href;
                    window.location.href = redirectURL;
                }
            },
            complete: function () {
                $.spinner().stop();
            }
        });
    });

    if (window.resources && window.resources.logout && window.resources.logout.logoutMessages) {
        let msgKey;

        if (sessionStorage.getItem('isLoggedIn') === 'false') {
            msgKey = 'logout';

            $.alert({
                content: window.resources.logout.logoutMessages[msgKey].message,
                placement: 'banner',
                theme: 'info',
                id: msgKey, // logout
                containerId: 'logoutMessage',
                dismissible: window.resources.logout.logoutMessages[msgKey].showCloseBtn,
                timeToLive: window.resources.logout.logoutMessages[msgKey].timeToLive
            });

            sessionStorage.removeItem('isLoggedIn');
            sessionStorage.removeItem('isFirstLogin');
            sessionStorage.removeItem('reloaded');
        /**
         * when the user does not log out intentionally and the login form is on the page, their session timed out so display the timeout message
         */
        } else if (sessionStorage.getItem('isLoggedIn') === 'true' && $('form.login').length && sessionStorage.getItem('isTimeoutAvailable') === true) {
            msgKey = 'logout_auto';

            $.alert({
                content: window.resources.logout.logoutMessages[msgKey].message,
                placement: 'banner',
                theme: 'info',
                id: msgKey, // logout_auto
                containerId: 'logoutMessage',
                dismissible: window.resources.logout.logoutMessages[msgKey].showCloseBtn,
                autoClose: window.resources.logout.logoutMessages[msgKey].autoClose
            });

            sessionStorage.removeItem('isLoggedIn');
            sessionStorage.removeItem('isFirstLogin');
            sessionStorage.removeItem('reloaded');
        }
    }
    return false;
};

login.showLoyaltyBanner = function () {
    /**
     * when user submits footer "Join Now" form successfully, this banner is shown with confirmation message.
     */
    if (sessionStorage.getItem('joinedRewards') === 'true') {
        let successAlertObj = window.resources.logout.logoutMessages.footer_loyalty_rewards_registration;
        successAlertObj.id = 'rewards-signup-msg';
        successAlertObj.containerId = 'loyaltyRewardsFooterLogInSuccess';

        $.alert(successAlertObj);

        sessionStorage.removeItem('joinedRewards');
    }
};

login.getEmailIdFromSession = function () {
    /**
     * fetch the email id from the session and update to reset password input field
     */
    var $resetPwdInput = $('#reset-password-email');

    if ($resetPwdInput.length && Object.hasOwnProperty.call(sessionStorage, 'userEmailId') && !window.IsKiosk) {
        var emailId = sessionStorage.getItem('userEmailId');

        $resetPwdInput.val(emailId).closest('.form-group').addClass('focus-fill');
        // Form validation on pageload may have already executed so we trigger 'change' event to ensure value is considered.
        $resetPwdInput.trigger('change');
        sessionStorage.removeItem('userEmailId');
    }
};

module.exports = login;
